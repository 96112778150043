<template>
    <div>
        <h3 class="subheading">Clip current asset</h3>

        <section class="section-container">
            <v-layout row>
                <v-flex class="clipping-player">
                    <VideoPlayer
                        @clipped="range = $event"
                        :clipping="true"
                        :entitlements="item.attributes.accessData.entitlements"
                        :title="item.attributes.title"
                        :playbackID="item.attributes.assetData.playbackIds[0].id"
                        :policy="item.attributes.assetData.playbackIds[0].policy"
                        :storyboardToken="item.attributes.assetData.storyboardToken"
                        :videoID="item.attributes.videoID"
                    />
                </v-flex>
            </v-layout>
        </section>

        <h3 class="subheading">Create clip</h3>
        <section class="section-container">
            <v-layout row>
                <v-flex
                    mr-4
                    class="half"
                >
                    <div>
                        <label
                            class="label published-row__label"
                            for="policy"
                        >Policy</label>
                        <v-select
                            id="policy"
                            v-model="policy"
                            item-text="name"
                            item-value="value"
                            :items="[
                            { name: 'Signed, secure the asset', value: 'signed' },
                            { name: 'Public, can be viewed anywhere', value: 'public' },
                        ]"
                            solo
                            flat
                            required
                            readonly
                        />
                    </div>
                </v-flex>

                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-4
                    class="half"
                >
                    <div>
                        <label
                            class="label published-row__label"
                            for="watermark"
                        >Watermark Image</label>
                        <v-select
                            :items="[
                            { text: 'Use Light Mode Brand Logo', value: 'lightMode' },
                            { text: 'Use Dark Mode Brand Logo', value: 'darkMode' },
                            { text: 'Use Custom Watermark', value: 'custom' },
                            { text: 'No watermark', value: 'hide' },
                        ]"
                            solo
                            flat
                            v-model="watermarkType"
                        >
                        </v-select>
                        <ImageUploader
                            v-if="watermarkType === 'custom'"
                            :title="'Custom Watermark Image'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="watermark.imageID"
                            @update="updateImage($event)"
                            :desc="'<p>Recommended size: </p>'"
                        />
                        <p v-else-if="watermarkType === 'hide'">
                            No watermark will be added to this clip
                        </p>
                    </div>
                </v-flex>

                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Vertical Align</label>
                    <v-select
                        :items="[
                            { text: 'Top', value: 'top' },
                            { text: 'Bottom', value: 'bottom' },
                        ]"
                        solo
                        flat
                        v-model="watermark.verticalAlign"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Vertical Margin</label>
                    <v-text-field
                        id="field"
                        v-model="watermark.verticalMargin"
                        solo
                        flat
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Format - 1234px</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Horizontal Align</label>
                    <v-select
                        :items="[
                            { text: 'Right', value: 'right' },
                            { text: 'Left', value: 'left' },
                        ]"
                        solo
                        flat
                        v-model="watermark.horizontalAlign"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Horizontal Margin</label>
                    <v-text-field
                        id="field"
                        v-model="watermark.horizontalMargin"
                        solo
                        flat
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Format - 1234px</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Watermark Width</label>
                    <v-text-field
                        id="field"
                        v-model="watermark.width"
                        solo
                        flat
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Format - 1234px</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="watermarkType != 'hide'"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Watermark Example - This is a representation of how your watermark
                        may appear.</label>
                    <div
                        class="watermark-example"
                        :class="watermarkType === 'lightMode'
                                ? 'watermark-example--light'
                                : 'watermark-example--dark'
                            "
                        :style="{
                            justifyContent:
                                watermark.verticalAlign === 'top' ? 'flex-start' : 'flex-end',
                            alignItems:
                                watermark.horizontalAlign === 'right'
                                    ? 'flex-end'
                                    : 'flex-start',
                        }"
                    >
                        <div
                            class="watermark-example__watermark"
                            :style="{
                            width: `${parseInt(watermark.width) / 3}px`,
                            marginTop: `${parseInt(watermark.verticalMargin) / 3}px`,
                            marginBottom: `${parseInt(watermark.verticalMargin) / 3}px`,
                            marginLeft: `${parseInt(watermark.horizontalMargin) / 3}px`,
                            marginRight: `${parseInt(watermark.horizontalMargin) / 3}px`,
                        }"
                        >
                            <img :src="`${this.imagePath}/${this.watermark.url}`" />
                        </div>
                    </div>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <!-- <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label published-row__label" for="published">New Asset?</label>
            <v-select
              id="published"
              v-model="createNewAsset"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Yes, create new asset', value: 1 },
                { name: 'No, replace this asset', value: 0 }
              ]"
              solo
              flat
              required
              @change="status = 'needsSaving'"/>
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout> -->

            <v-layout row>
                <v-flex
                    mr-4
                    class="half"
                >
                    <v-btn
                        @click="createClip()"
                        color="accent"
                        outline
                        flat
                    >Create Clip</v-btn>
                </v-flex>

                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import VideoPlayer from "@/components/Streamline/VideoPlayer";

export default {
    name: "VideoNewClip",

    components: {
        ImageUploader,
        VideoPlayer,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    watch: {
        watermarkType: function (val) {
            console.log("watermarkType Changed", val);
            if (val === "hide") {
                console.log("watermarkType Changed to hide", val);
                this.watermark.url = null;
            } else if (val === "custom") {
                console.log("watermarkType Changed to custom", val);
                // this.watermark.url = null
            } else if (val === "lightMode") {
                console.log("watermarkType Changed to lightMode", val);
                this.watermark.url = this.watermarkLightmodeKey;
            } else if (val === "darkMode") {
                console.log("watermarkType Changed to darkMode", val);
                this.watermark.url = this.watermarkDarkmodeKey;
            }
        },
    },

    created() {
        this.betaFeatures = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/defaultSettings.js");
        this.modules = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    data: () => ({
        videosApi: process.env.VUE_APP_VIDEOS_API,
        streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
        modules: null,
        imagePath: process.env.VUE_APP_IMAGEURL,
        betaFeatures: null,
        range: null,
        type: "newAsset",
        policy: "signed",
        //Assume 16:9
        watermarkLightmodeID: null,
        watermarkLightmodeKey: null,
        watermarkDarkmodeID: null,
        watermarkDarkmodeKey: null,
        watermarkType: "darkMode",
        watermark: {
            url: "",
            verticalAlign: "bottom",
            verticalMargin: "40px",
            horizontalAlign: "right",
            horizontalMargin: "40px",
            width: "200px",
        },
    }),

    mounted() {
        this.getWatermarkOptions();
    },

    methods: {
        createClip() {
            console.log("createClip");
            if (this.type === "newAsset") {
                // ${process.env.VUE_APP_STREAMLINEAPI}/videos/<video_id>/clip?startTime=<start_time_in_sec>&endTime=<end_time_in_sec>

                let watermarkRequest;

                if (this.watermark.url) {
                    watermarkRequest = `&watermark=${this.imagePath}/${this.watermark.url}&verticalAlign=${this.watermark.verticalAlign}&verticalMargin=${this.watermark.verticalMargin}&horizontalAlign=${this.watermark.horizontalAlign}&horizontalMargin=${this.watermark.horizontalMargin}&width=${this.watermark.width}`;
                }

                console.log("watermark request", watermarkRequest);

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "GET",
                        url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/clip?startTime=${this.range[0]}&endTime=${this.range[1]}&playbackPolicy=${this.policy}${watermarkRequest}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            console.log("Create clip:", response);
                            // this.item = response.data.data
                            this.$store.commit("completeLoading");
                            this.status = "saved";

                            if (confirm("Clip created, go to asset?")) {
                                this.$router.go({
                                    path: `/streamline/${response.data.data.id}`,
                                });
                            }
                        })
                        .catch((e) => {
                            console.error("Problem creating clip", e);
                            this.$store.commit("completeLoading");
                            this.errorMessage = "Problem creating clip";
                        });
                });
            }
        },

        getWatermarkOptions() {
            //hit globals endpoint
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.modules.default.Streamline
                            ? this.streamlineApi
                            : this.videosApi
                        }/config`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        this.watermarkLightmodeID =
                            response.data.data.attributes.logoLightMediaLibraryID;
                        this.watermarkLightmodeKey =
                            response.data.data.attributes.logoLightKey;
                        this.watermarkDarkmodeID =
                            response.data.data.attributes.logoDarkMediaLibraryID;
                        this.watermarkDarkmodeKey =
                            response.data.data.attributes.logoDarkKey;
                        this.watermark.url = response.data.data.attributes.logoDarkKey;
                    })
                    .catch((e) => {
                        console.error("Problem getting global settings", e);
                        // this.status = 'needsSaving'
                        this.errorMessage = "Problem getting global settings";
                    });
            });

            //assign relevant bits
        },

        updateImage(payload) {
            console.log("updateImage", payload);
            if (payload.mediaLibraryID) {
                this.watermark.imageID = payload.mediaLibraryID;
                this.watermark.imageKey = payload.imageKey;
                this.watermark.url = payload.imageKey;
            } else {
                this.watermark.imageID = "";
                this.watermark.imageKey = "";
            }
        },
    },
};
</script>

<style
    scoped
    lang="scss"
>
.half {
    width: 50%;
}

.third-x2 {
    width: 66.66%;
}

.third {
    width: 33.33%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}

.clipping-player {
    position: relative;
}

.watermark-example {
    position: relative;
    height: 300px;
    width: 533.33px;
    display: flex;
    flex-direction: column;

    &--light {
        background-color: lightgray;
    }

    &--dark {
        background-color: darkgray;
    }

    &__watermark {
        img {
            max-height: 100%;
            max-width: 100%;
        }
    }
}
</style>
