<template>
  <div>
    <h3 class="subheading">Create Social Clip</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex>
          <VideoPlayer
            @clipped="range = $event"
            :clipping="true"
            :entitlements="item.attributes.accessData.entitlements"
            :title="item.attributes.title"
            :playbackID="item.attributes.assetData.playbackIds[0].id"
            :policy="item.attributes.assetData.playbackIds[0].policy"
            :storyboardToken="item.attributes.assetData.storyboardToken"
            :videoID="item.attributes.videoID" />
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Create clip</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label published-row__label" for="policy">Policy</label>
            <v-select
              id="policy"
              v-model="policy"
              item-text='name'
              item-value='value'
              readonly
              :items="[
                { name: 'Signed, secure the asset', value: 'signed' },
                { name: 'Public, can be viewed anywhere', value: 'public' },
              ]"
              solo
              flat
              required />
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label published-row__label" for="watermark">Watermark Image URL</label>
            <v-text-field
              id="watermark"
              v-model="watermark.url"
              solo
              flat
            ></v-text-field>
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <!-- <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label published-row__label" for="published">New Asset?</label>
            <v-select
              id="published"
              v-model="createNewAsset"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Yes, create new asset', value: 1 },
                { name: 'No, replace this asset', value: 0 }
              ]"
              solo
              flat
              required
              @change="status = 'needsSaving'"/>


          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout> -->

      <v-layout row>
        <v-flex mr-4 class="half">
          <v-btn
          @click="createClip()"
          color="accent"
          outline
          flat>Create Clip</v-btn>
        </v-flex>

        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>
    </section>
  </div>
</template>

<script>
import axios from "axios"
import VideoPlayer from "@/components/Streamline/VideoPlayer"

export default {
  name: "VideoSocialClip",

  components: {
    VideoPlayer
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data:() => ({
    range: null,
    type: "socialClip",
    policy: "public",
    watermark: {
      url: "https://www.avfc.co.uk/villatv.png",
      verticalAlign: "bottom",
      verticalMargin: "40px",
      horizontalAlign: "right",
      horizontalMargin: "40px",
      width: "200px"
    }
  }),

  methods: {
    createClip() {
      console.log("createClip")
      if (this.type === "socialClip") {
        // ${process.env.VUE_APP_STREAMLINEAPI}/videos/<video_id>/clip?startTime=<start_time_in_sec>&endTime=<end_time_in_sec>

        let watermarkRequest;

        if (this.watermark.url) {
          watermarkRequest = `&watermark=${this.watermark.url}&verticalAlign=${this.watermark.verticalAlign}&verticalMargin=${this.watermark.verticalMargin}&horizontalAlign=${this.watermark.horizontalAlign}&horizontalMargin=${this.watermark.horizontalMargin}&width=${this.watermark.width}&socialClip=true&mp4Support=true`
        }

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "GET",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/clip?startTime=${this.range[0]}&endTime=${this.range[1]}&playbackPolicy=${this.policy}${watermarkRequest}`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            console.log("Create clip:", response);
            // this.item = response.data.data
            this.$store.commit("completeLoading");
            this.status = "saved";

            if(confirm("Clip created, go to asset?")) {
              this.$router.go({ path: `/streamline/${response.data.data.id}` })
            }
          })
          .catch(e => {
            console.error("Problem creating clip", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem creating clip";
          });
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .third-x2 {
    width: 66.66%;
  }

  .third {
    width: 33.33%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
