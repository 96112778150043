var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"subheading"},[_vm._v("Clip current asset")]),_c('section',{staticClass:"section-container"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"clipping-player"},[_c('VideoPlayer',{attrs:{"clipping":true,"entitlements":_vm.item.attributes.accessData.entitlements,"title":_vm.item.attributes.title,"playbackID":_vm.item.attributes.assetData.playbackIds[0].id,"policy":_vm.item.attributes.assetData.playbackIds[0].policy,"storyboardToken":_vm.item.attributes.assetData.storyboardToken,"videoID":_vm.item.attributes.videoID},on:{"clipped":function($event){_vm.range = $event}}})],1)],1)],1),_c('h3',{staticClass:"subheading"},[_vm._v("Create clip")]),_c('section',{staticClass:"section-container"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-4":""}},[_c('div',[_c('label',{staticClass:"label published-row__label",attrs:{"for":"policy"}},[_vm._v("Policy")]),_c('v-select',{attrs:{"id":"policy","item-text":"name","item-value":"value","items":[
                        { name: 'Signed, secure the asset', value: 'signed' },
                        { name: 'Public, can be viewed anywhere', value: 'public' },
                    ],"solo":"","flat":"","required":"","readonly":""},model:{value:(_vm.policy),callback:function ($$v) {_vm.policy=$$v},expression:"policy"}})],1)]),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i')])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-4":""}},[_c('div',[_c('label',{staticClass:"label published-row__label",attrs:{"for":"watermark"}},[_vm._v("Watermark Image")]),_c('v-select',{attrs:{"items":[
                        { text: 'Use Light Mode Brand Logo', value: 'lightMode' },
                        { text: 'Use Dark Mode Brand Logo', value: 'darkMode' },
                        { text: 'Use Custom Watermark', value: 'custom' },
                        { text: 'No watermark', value: 'hide' },
                    ],"solo":"","flat":""},model:{value:(_vm.watermarkType),callback:function ($$v) {_vm.watermarkType=$$v},expression:"watermarkType"}}),(_vm.watermarkType === 'custom')?_c('ImageUploader',{attrs:{"title":'Custom Watermark Image',"widthOptionEnabled":false,"mediaLibraryID":_vm.watermark.imageID,"desc":'<p>Recommended size: </p>'},on:{"update":function($event){return _vm.updateImage($event)}}}):(_vm.watermarkType === 'hide')?_c('p',[_vm._v(" No watermark will be added to this clip ")]):_vm._e()],1)]),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i')])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Vertical Align")]),_c('v-select',{attrs:{"items":[
                        { text: 'Top', value: 'top' },
                        { text: 'Bottom', value: 'bottom' },
                    ],"solo":"","flat":""},model:{value:(_vm.watermark.verticalAlign),callback:function ($$v) {_vm.$set(_vm.watermark, "verticalAlign", $$v)},expression:"watermark.verticalAlign"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i')])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Vertical Margin")]),_c('v-text-field',{attrs:{"id":"field","solo":"","flat":""},model:{value:(_vm.watermark.verticalMargin),callback:function ($$v) {_vm.$set(_vm.watermark, "verticalMargin", $$v)},expression:"watermark.verticalMargin"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("Format - 1234px")])])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Horizontal Align")]),_c('v-select',{attrs:{"items":[
                        { text: 'Right', value: 'right' },
                        { text: 'Left', value: 'left' },
                    ],"solo":"","flat":""},model:{value:(_vm.watermark.horizontalAlign),callback:function ($$v) {_vm.$set(_vm.watermark, "horizontalAlign", $$v)},expression:"watermark.horizontalAlign"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i')])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Horizontal Margin")]),_c('v-text-field',{attrs:{"id":"field","solo":"","flat":""},model:{value:(_vm.watermark.horizontalMargin),callback:function ($$v) {_vm.$set(_vm.watermark, "horizontalMargin", $$v)},expression:"watermark.horizontalMargin"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("Format - 1234px")])])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Watermark Width")]),_c('v-text-field',{attrs:{"id":"field","solo":"","flat":""},model:{value:(_vm.watermark.width),callback:function ($$v) {_vm.$set(_vm.watermark, "width", $$v)},expression:"watermark.width"}})],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("Format - 1234px")])])])],1),(_vm.watermarkType != 'hide')?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-5":""}},[_c('label',{staticClass:"label",attrs:{"for":"field"}},[_vm._v("Watermark Example - This is a representation of how your watermark may appear.")]),_c('div',{staticClass:"watermark-example",class:_vm.watermarkType === 'lightMode'
                            ? 'watermark-example--light'
                            : 'watermark-example--dark',style:({
                        justifyContent:
                            _vm.watermark.verticalAlign === 'top' ? 'flex-start' : 'flex-end',
                        alignItems:
                            _vm.watermark.horizontalAlign === 'right'
                                ? 'flex-end'
                                : 'flex-start',
                    })},[_c('div',{staticClass:"watermark-example__watermark",style:({
                        width: `${parseInt(_vm.watermark.width) / 3}px`,
                        marginTop: `${parseInt(_vm.watermark.verticalMargin) / 3}px`,
                        marginBottom: `${parseInt(_vm.watermark.verticalMargin) / 3}px`,
                        marginLeft: `${parseInt(_vm.watermark.horizontalMargin) / 3}px`,
                        marginRight: `${parseInt(_vm.watermark.horizontalMargin) / 3}px`,
                    })},[_c('img',{attrs:{"src":`${this.imagePath}/${this.watermark.url}`}})])])]),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i',[_vm._v("-")])])])],1):_vm._e(),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"half",attrs:{"mr-4":""}},[_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.createClip()}}},[_vm._v("Create Clip")])],1),_c('v-flex',{staticClass:"half",attrs:{"mt-4":""}},[_c('p',[_c('i')])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }