<template>
  <section v-if="dataLoaded" class="main-body">
    <h3 class="subheading">Details</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="title">Title</label>
          <v-text-field
            id="title"
            v-model="item.attributes.title"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            Title of the event<span v-if="modules.default.Streamline">
              - Not used in streamline matches</span
            >
          </p>
        </v-flex>
      </v-layout>

      <!-- Currently not in use -->
      <!-- <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="subtitle">Subtitle</label>
          <v-text-field
            id="subtitle"
            v-model="item.attributes.subtitle"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>The subtitle can be used as a label to identify different subsets of events</p>
        </v-flex>
      </v-layout> -->

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="description">Description</label>
          <v-textarea
            solo
            flat
            rows="4"
            id="description"
            v-model="item.attributes.description"
            @change="updateData()"
          ></v-textarea>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            Short description of the event. This can take basic html
            <span v-if="modules.default.Streamline">
              - Not used in streamline matches</span
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label" for="type">Type<sup>*</sup></label>
            <v-select
              id="type"
              v-model="item.attributes.type"
              item-text="name"
              item-value="value"
              :items="eventTypeOptions"
              solo
              flat
              required
              @change="updateData()"
              :error="basicValidateCheck(item.attributes.type)"
            />
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p>
            <i>Required</i> - The type of event will determine how this item
            will work and what additional data will be required. This will also
            provide filtering of these types
          </p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Images</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex>
          <ImageUploader
            :title="'Landscape Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="item.attributes.imageData.landscape.imageID"
            @update="updateImage($event)"
            :desc="'<p>Recommended size: 16:9</p>'"
          />
        </v-flex>
      </v-layout>
    </section>

    <template v-if="item.attributes.type === 'streamline'">
      <h3 class="subheading">Streamline Info</h3>
      <section class="section-container">
        <v-layout row class="section-divider">
          <v-flex mr-4 class="half">
            <div>
              <label class="label" for="streamType">Stream Type</label>
              <v-select
                v-if="item.attributes.streamlineData"
                id="streamType"
                v-model="item.attributes.streamlineData.streamType"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Match', value: 'match' },
                  { name: 'Other', value: 'other' },
                ]"
                solo
                flat
                required
                @change="updateData()"
              />
            </div>
          </v-flex>

          <v-flex mt-4 class="half">
            <p>
              Select "Match" if you want to create a calendar item related to a
              custom fixture. A match will require link to the match centre and
              a stream will link to streaming pages
            </p>
          </v-flex>
        </v-layout>

        <v-layout
          class="section-divider"
          row
          v-if="
            item.attributes.streamlineData &&
            item.attributes.streamlineData.streamType === 'match'
          "
        >
          <v-flex mr-5 class="half">
            <match-picker @updated="setMatch($event)" />
            <p>
              {{ item.attributes.streamlineData.teamID }} /
              {{ item.attributes.streamlineData.seasonID }} /
              {{ item.attributes.streamlineData.matchID }}
            </p>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              The match picker will allow you to select any match from your
              system and associate it with this event
            </p>
          </v-flex>
        </v-layout>

        <h3 class="subheading mt-0">Assets</h3>

        <p>
          The asset picker below will select which Live Stream endpoints this
          Streamline event is tied to
        </p>

        <v-layout row v-if="!updatingAssets && item.attributes.streamlineData">
          <v-flex>
            <table class="asset-table">
              <tr
                v-for="(asset, index) in item.attributes.streamlineData
                  .assetData"
                :key="asset.id"
              >
                <td class="table-data">
                  <v-select
                    v-model="
                      item.attributes.streamlineData.assetData[index].mediaType
                    "
                    readonly
                    item-text="name"
                    item-value="value"
                    :items="[
                      { name: 'Video/Audio Stream', value: 'video' },
                      { name: 'Audio Only Stream', value: 'audio' },
                    ]"
                    solo
                    flat
                    hide-details
                    required
                    @change="updateData()"
                  />
                </td>
                <td class="table-data">
                  <live-asset-picker
                    :assetID="
                      item.attributes.streamlineData.assetData[index].assetID
                    "
                    @assetUpdated="updateAsset($event, index)"
                    @assetCleared="clearAsset(index)"
                  />
                </td>
              </tr>
              <!-- <tr>
                <td colspan="2"><v-btn @click="addAsset()">Add asset</v-btn></td>
              </tr> -->
            </table>
          </v-flex>
        </v-layout>

        <v-layout class="row-space" row v-if="modules.default.Streamline">
          <v-flex mr-5 class="half">
            <label class="label" for="packagesLink"
              >Default streamline button packages link</label
            >
            <v-text-field
              id="packagesLink"
              v-model="item.attributes.streamlinePackagesLink"
              solo
              flat
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              When a user does not have the correct entitlements to watch a
              video, this button automatically provides a CTA for them to
              purchase it. By default it takes you to "/videos/subscriptions" or
              another predefined packages page in the global videos settings,
              but here you can override the link for this item.
            </p>
          </v-flex>
        </v-layout>
      </section>
    </template>

    <template>
      <h3 class="subheading">Custom Link - Calendar Widget</h3>
      <section class="section-container">
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="url">Custom Button Url</label>
            <v-text-field
              id="url"
              v-model="item.attributes.externalButtonUrl"
              solo
              flat
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              Link to find out more information about this event.
              <span v-if="modules.default.Streamline"
                >This will override Streamline asset links and can be used to
                link to the match centre or anywhere else</span
              >
            </p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="url">Custom Button Text</label>
            <v-text-field
              id="url"
              v-model="item.attributes.externalButtonText"
              solo
              flat
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              Text to find out more information about this event.
              <span v-if="modules.default.Streamline"
                >This will override Streamline asset links and can be used to
                link to the match centre or anywhere else</span
              >
            </p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="button-icon">Button Icon</label>
            <v-select
              id="button-icon"
              :items="icons"
              item-text="name"
              item-value="value"
              v-model="item.attributes.externalButtonIcon"
              solo
              flat
              @change="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
      </section>
    </template>

    <h3 class="subheading">Publishing</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="start">Start date<sup>*</sup></label>
          <datetime
            v-model="item.attributes.startAt"
            type="datetime"
            @change="updateData()"
            :class="{
              'datetime-error': basicValidateCheck(item.attributes.startAt),
            }"
          ></datetime>
          <p class="utc-note">
            This will start at {{ item.attributes.startAt }}
          </p>

          <label class="label" for="end">End date<sup>*</sup></label>
          <datetime
            v-model="item.attributes.finishAt"
            type="datetime"
            @change="updateData()"
            :class="{
              'datetime-error': basicValidateCheck(item.attributes.finishAt),
            }"
          ></datetime>
          <p class="utc-note">
            This will end at {{ item.attributes.finishAt }}
          </p>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>Required</i> - A start and end date is required to know where
            this event will display in the calendar. These dates may be used to
            dynamically show events in different components
          </p>

          <p>
            <i
              >Please note - these are not used for scheduling or unpublishing
              events</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label" for="published">Published<sup>*</sup></label>
            <v-select
              id="published"
              v-model="item.attributes.published"
              item-text="name"
              item-value="value"
              :items="[
                { name: 'Publish', value: 1 },
                { name: 'Draft', value: 0 },
              ]"
              solo
              flat
              required
              @change="updateData()"
            />
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p>
            <i>Required</i> - Events will only display if they are published
          </p>
        </v-flex>
      </v-layout>
    </section>

    <v-btn color="error" @click="deleteItem()">Delete</v-btn>
    <v-btn @click="returnToCalendar()">Return</v-btn>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      @buttonClicked="saveItem()"
    />
  </section>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import MatchPicker from "@/components/Library/MatchPicker.vue";
import StickyFooter from "@/components/Library/StickyFooter";
import LiveAssetPicker from "@/components/Library/LiveAssetPicker";

export default {
  name: "CalendarEdit",

  components: {
    ImageUploader,
    StickyFooter,
    MatchPicker,
    LiveAssetPicker,
  },

  data: () => ({
    modules: null,
    status: "saved",
    errorMessage: "",
    dataLoaded: false,
    updatingAssets: false,
    validating: false,
    eventTypeOptions: [],
    item: {
      attributes: {
        published: 0,
        startAt: "",
        finishAt: "",
        streamlineData: {
          assetData: [
            {
              id: "asset-1",
              mediaType: "video",
              assetID: "",
            },
            {
              id: "asset-2",
              mediaType: "audio",
              assetID: "",
            },
          ],
        },
        externalButtonText: "",
        externalButtonUrl: "",
        externalButtonIcon: "",
        streamlinePackagesLink: "",
        imageData: {
          landscape: {},
        },
      },
    },
    icons: [
      {
        name: "None",
        value: null,
      },
      {
        name: "Article",
        value: "Article",
      },
      {
        name: "Audio",
        value: "Audio",
      },
      {
        name: "Ball",
        value: "Ball",
      },
      {
        name: "Gallery",
        value: "Camera",
      },
      {
        name: "Live",
        value: "Live",
      },
      {
        name: "Quiz",
        value: "Quiz",
      },
      {
        name: "Tickets",
        value: "Tickets",
      },
      {
        name: "Video",
        value: "Video",
      },
    ],
  }),

  created() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    this.eventTypeOptions.push({ name: "Event", value: "event" });
    if (this.modules.default.Streamline) {
      this.eventTypeOptions.push({ name: "Streamline", value: "streamline" });
    }
  },

  mounted() {
    if (this.$route.params.id === "create-item") {
      this.dataLoaded = true;
      this.status = "needsSaving";
    } else {
      this.fetchItem();
    }
  },

  methods: {
    updateData() {
      this.status = "needsSaving";
    },

    updateImage(payload) {
      console.log("updateImage", payload);
      if (payload.mediaLibraryID) {
        this.item.attributes.imageData.landscape.imageID =
          payload.mediaLibraryID;
        this.item.attributes.imageData.landscape.imageKey = payload.imageKey;
      } else {
        this.item.attributes.imageData.landscape.imageID = "";
        this.item.attributes.imageData.landscape.imageKey = "";
      }

      this.updateData();
    },

    setMatch(payload) {
      console.log("Payload", payload);
      // Optional elements which are not initially mentioned in the 'data', so need to use "$set" to ensure reactivity
      this.$set(
        this.item.attributes.streamlineData,
        "matchID",
        payload.matchID
      );
      this.$set(this.item.attributes.streamlineData, "teamID", payload.teamID);
      this.$set(
        this.item.attributes.streamlineData,
        "seasonID",
        payload.seasonID
      );

      this.updateData();
    },

    fetchItem() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${process.env.VUE_APP_CALENDARAPI}/calendar/${this.$route.params.id}`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            console.log("response", response);
            this.item = response.data.data;
            this.dataLoaded = true;
          })
          .catch((e) => {
            console.error("Problem retrieving calendar item", e);
          });
      });
    },

    createItem() {
      this.$store.commit("startLoading");

      const calendarItem = {
        title: this.item.attributes.title,
        // subtitle: this.item.attributes.subtitle,
        description: this.item.attributes.description,
        type: this.item.attributes.type,
        startAt: this.item.attributes.startAt,
        finishAt: this.item.attributes.finishAt,
        published: this.item.attributes.published,
        externalButtonText: this.item.attributes.externalButtonText,
        externalButtonUrl: this.item.attributes.externalButtonUrl,
        // Sergie: Temporarily commented out until a proper backend fix is done that allows these properties in the api
        // externalButtonIcon: this.item.attributes.externalButtonIcon,
        // streamlinePackagesLink: this.item.attributes.streamlinePackagesLink,
        imageData: {
          landscape: this.item.attributes.imageData.landscape,
        },
      };

      if (this.item.attributes.type === "streamline") {
        calendarItem.streamlineData = this.item.attributes.streamlineData;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_CALENDARAPI}/calendar`,
          headers: { Authorization: jwt },
          data: {
            data: {
              attributes: calendarItem,
              type: "calendar",
            },
          },
        })
          .then((response) => {
            console.log("Created calendar item:", response);
            this.$store.commit("completeLoading");
            this.status = "saved";

            this.$router.push({ path: `/calendar/${response.data.data.id}` });
          })
          .catch((e) => {
            console.error("Problem creating calendar item", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
            this.errorMessage = "Problem creating calendar item";
          });
      });
    },

    saveItem() {
      if (
        this.item.attributes.type &&
        this.item.attributes.startAt &&
        this.item.attributes.finishAt
      ) {
        if (this.$route.params.id === "create-item") {
          this.createItem();
        } else {
          this.$store.commit("startLoading");

          const calendarItem = {
            title: this.item.attributes.title,
            // subtitle: this.item.attributes.subtitle,
            description: this.item.attributes.description,
            type: this.item.attributes.type,
            startAt: this.item.attributes.startAt,
            finishAt: this.item.attributes.finishAt,
            published: this.item.attributes.published,
            externalButtonText: this.item.attributes.externalButtonText,
            externalButtonUrl: this.item.attributes.externalButtonUrl,
            // Sergie: Temporarily commented out until a proper backend fix is done that allows these properties in the api
            // externalButtonIcon: this.item.attributes.externalButtonIcon,
            // streamlinePackagesLink: this.item.attributes.streamlinePackagesLink,
            imageData: {
              landscape: this.item.attributes.imageData.landscape,
            },
          };

          if (this.item.attributes.type === "streamline") {
            calendarItem.streamlineData = this.item.attributes.streamlineData;
          }

          this.$getCurrentUserJwToken().subscribe((jwt) => {
            axios({
              method: "PATCH",
              url: `${process.env.VUE_APP_CALENDARAPI}/calendar/${this.$route.params.id}`,
              headers: { Authorization: jwt },
              data: {
                data: {
                  type: "calendar",
                  id: this.$route.params.id,
                  attributes: calendarItem,
                },
              },
            })
              .then((response) => {
                console.log("Saving calendar item:", response);
                this.$store.commit("completeLoading");
                this.status = "saved";
              })
              .catch((e) => {
                console.error("Problem saving calendar item", e);
                this.$store.commit("completeLoading");
                this.status = "needsSaving";
                this.errorMessage = "Problem saving calendar item";
              });
          });
        }
      } else {
        alert(
          "You must have a type, start date, end date, and published status"
        );
        this.validating = true;
      }
    },

    deleteItem() {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios
            .delete(
              `${process.env.VUE_APP_CALENDARAPI}/calendar/${this.$route.params.id}`,
              { headers: { Authorization: jwt } }
            )
            .then((response) => {
              console.log("response", response);

              this.$router.push({ path: "/calendar" });
            })
            .catch((e) => {
              console.error("Problem deleting calendar item", e);
            });
        });
      }
    },

    addAsset() {
      this.updatingAssets = true;
      console.log("addAsset", this.item.attributes);
      if (!this.item.attributes.streamlineData.assetData) {
        this.item.attributes.streamlineData.assetData = [];
      }

      this.item.attributes.streamlineData.assetData.push({
        id: this.$uuid.v1(),
        mediaType: "",
        assetID: "",
      });

      this.updatingAssets = false;
    },

    updateAsset(payload, index) {
      this.item.attributes.streamlineData.assetData[index].assetID = payload.id;
    },

    clearAsset(index) {
      this.item.attributes.streamlineData.assetData[index].assetID = "";
    },

    basicValidateCheck(value) {
      if (this.validating && !value) {
        return true;
      }
    },

    returnToCalendar() {
      this.$router.push({ path: "/calendar" });
    },
  },
};
</script>

<style scoped lang="scss">
.half {
  width: 50%;
}

.third-x2 {
  width: 66.66%;
}

.third {
  width: 33.33%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.image {
  width: 100%;
  max-width: 300px;
}

.triple-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__item {
    width: calc(33.33% - 16px);
  }
}

.info-table {
  width: 100%;
  border: 1px solid #dfe3e9;
  border-radius: 4px;

  td {
    padding: 4px 8px;
    border-bottom: 1px solid #dfe3e9;

    &:first-child {
      padding-right: 20px;
      font-weight: 500;
      border-right: 1px solid #dfe3e9;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
}

.asset-table {
  padding: 16px;
  width: 100%;
  background: #e6e8f1;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
}

.table-data,
.table-data * {
  vertical-align: top;
}

.row-space {
  margin-top: 20px;
}
</style>
