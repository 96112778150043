<template>
    <section class="page-content">
        <v-tabs v-model="active">
            <v-tab :ripple="{ class: 'accent--text' }">Details</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <h3 class="subheading">Asset Info</h3>
                <section class="section-container">
                    <template v-if="item.attributes.assetData.status === 'errored'">
                        <p
                            v-for="(errorMessage, index) in item.attributes.assetData.errors
                                .messages"
                            :key="`error-${index}`"
                            class="error-message"
                        >
                            {{ errorMessage }}
                        </p>
                    </template>

                    <table class="info-table">
                        <tr>
                            <td>Asset ID</td>
                            <td>
                                <code>{{ item.id }}</code>
                            </td>
                        </tr>
                        <tr>
                            <td>Mux Asset ID</td>
                            <td>{{ item.attributes.assetData.id }}</td>
                        </tr>
                        <tr>
                            <td>Playback Policy</td>
                            <td v-if="item.attributes.assetData.playbackIds">
                                {{ item.attributes.assetData.playbackIds[0].policy }}
                            </td>
                            <td v-else>No playback Policy</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{{ item.attributes.assetData.status }}</td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td>{{ item.attributes.assetData.duration }} seconds</td>
                        </tr>
                        <tr>
                            <td>Max Framerate</td>
                            <td>{{ item.attributes.assetData.maxFramerate }}</td>
                        </tr>
                        <tr>
                            <td>Max Resolution</td>
                            <td>{{ item.attributes.assetData.maxResolution }}</td>
                        </tr>
                        <tr>
                            <td>Aspect Ratio</td>
                            <td>{{ item.attributes.assetData.aspectRatio }}</td>
                        </tr>
                        <tr>
                            <td>Created At</td>
                            <td>
                                {{
                                    item.attributes.assetData.createdAt
                                    | moment("kk:mm (Z) DD/MM/YY")
                                }}
                            </td>
                        </tr>
                    </table>

                    <div class="preview-video">
                        <VideoPlayer
                            v-if="item.attributes.assetData.playbackIds"
                            :clipping="false"
                            :entitlements="item.attributes.accessData.entitlements"
                            :title="item.attributes.title"
                            :playbackID="item.attributes.assetData.playbackIds[0].id"
                            :policy="item.attributes.assetData.playbackIds[0].policy"
                            :storyboardToken="item.attributes.assetData.storyboardToken"
                            :videoID="item.attributes.videoID"
                        />

                        <p v-else>Playback ID missing</p>
                    </div>
                </section>

                <h3 class="subheading">Details</h3>
                <section class="section-container">
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="title"
                            >Title<sup>*</sup></label>
                            <v-text-field
                                id="title"
                                v-model="item.attributes.title"
                                solo
                                flat
                                @change="updateData()"
                                :error="basicValidateCheck(item.attributes.title)"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                <i>Required</i> - Title of video. When imported this will be the
                                file name
                            </p>
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="description"
                            >Description</label>
                            <v-text-field
                                id="description"
                                v-model="item.attributes.description"
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>Short description of the video</p>
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex
                            mr-4
                            class="half"
                        >
                            <div>
                                <label
                                    class="label"
                                    for="media-type"
                                >Media Type</label>
                                <v-select
                                    id="media-type"
                                    v-model="item.attributes.mediaType"
                                    readonly
                                    item-text="name"
                                    item-value="value"
                                    :items="[
                                        { name: 'Video', value: 'video' },
                                        { name: 'Social Clip', value: 'social-clip' },
                                    ]"
                                    solo
                                    flat
                                    required
                                    @change="updateData()"
                                />
                            </div>
                        </v-flex>

                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                The type of asset this is. More types will be added in future
                            </p>
                        </v-flex>
                    </v-layout>
                </section>

                <h3 class="subheading">Images</h3>
                <section class="section-container">
                    <!-- <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="id">Selected frame</label>
              <v-text-field
                id="id"
                v-model="item.attributes.startingFrame"
                type="number"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>

            <v-flex class="half">
              <img v-if="item.attributes.assetData.playbackIds" :src="`https://image.mux.com/${item.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?token=${item.attributes.assetData.thumbnailToken}&width=400&height=200`" class="image" />

              <p v-else>Playback ID missing</p>
            </v-flex>
          </v-layout> -->

                    <v-layout row>
                        <v-flex>
                            <template v-if="item.attributes.imageData">
                                <ImageUploader
                                    :title="'Custom Image'"
                                    :widthOptionEnabled="false"
                                    :mediaLibraryID="item.attributes.imageData.landscape.imageID"
                                    @update="updateCustomPoster($event)"
                                    :desc="'<p>Recommended size: 16:9</p><p>Replacement image for the asset. If not used then one will be grabbed from the video itself</p>'"
                                />
                            </template>

                            <p v-else>Image data missing</p>
                        </v-flex>
                    </v-layout>
                </section>

                <template v-if="modules.default.LFCExclusive || modules.default.StreamlineSubtitles">
                    <h3 class="subheading">Subtitles (WIP)</h3>
                    <section class="section-container">
                        <v-layout row>
                            <v-flex>
                                <table class="subtitles-table">
                                    <thead>
                                        <th>Language</th>
                                        <th>Text Type</th>
                                        <th>Status</th>
                                        <th>Updated At</th>
                                        <th
                                            class="refresh"
                                            align="right"
                                        >
                                            <v-btn
                                                icon
                                                @click="fetchSubtitles()"
                                            >
                                                <v-icon>refresh</v-icon>
                                            </v-btn>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="track in subtitles"
                                            :key="track.id"
                                        >
                                            <td>{{ track.attributes.assetData.name }}</td>
                                            <td>{{ track.attributes.assetData.textType }}</td>
                                            <td>{{ track.attributes.assetData.status }}</td>
                                            <td>
                                                {{
                                                    track.attributes.assetData.updatedAt
                                                    | moment("kk:mm (Z) DD/MM/YY")
                                                }}
                                            </td>
                                            <td align="right">
                                                <v-btn
                                                    color="error"
                                                    small
                                                    @click="deleteSubtitle(track.id)"
                                                >Delete</v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colspan="5"
                                                align="right"
                                            >
                                                <v-btn
                                                    color="accent"
                                                    @click="openSubtitlesEntry()"
                                                    small
                                                >Add Subtitle</v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-flex>
                        </v-layout>
                    </section>
                </template>

                <h3 class="subheading">Restrictions</h3>

                <section class="section-container">
                    <v-layout row>
                        <v-flex
                            mr-5
                            mb-4
                            class="half"
                        >
                            <entitlements-picker
                                :activeEntitlements="item.attributes.accessData.entitlements"
                                @updated="updatedEntitlements($event)"
                            />
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                Select or enter the entitlements you want to restrict this asset
                                to
                            </p>
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex
                            mr-5
                            mb-4
                            class="half"
                        >
                            <label
                                class="label"
                                for="enable-country-restrictions"
                            >Enable country restrictions</label>

                            <v-select
                                id="enable-country-restrictions"
                                v-model="enableCountryRestrictions"
                                item-text="name"
                                item-value="value"
                                :items="[
                                    { name: 'Yes', value: true },
                                    { name: 'No', value: false },
                                ]"
                                solo
                                flat
                                required
                                @change="clearCountryRestrictions()"
                            />
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                If country restrictions are disabled then users from all
                                countries will be able to access this resource
                            </p>
                        </v-flex>
                    </v-layout>

                    <template v-if="enableCountryRestrictions">
                        <v-layout row>
                            <v-flex
                                mr-5
                                class="half"
                            >
                                <label
                                    class="label"
                                    for="georestriction-profile"
                                >Georestriction Prepopulate</label>
                                <v-select
                                    v-if="georestrictionProfilesLoaded"
                                    id="georestriction-profile"
                                    v-model="item.attributes.accessData.countryProfile"
                                    :items="georestrictionProfiles"
                                    item-text="profileName"
                                    item-value="id"
                                    return-object
                                    solo
                                    flat
                                    @change="
                                        selectGeorestrictionProfile($event);
                                    updateData();
                                    "
                                >
                                </v-select>
                            </v-flex>
                            <v-flex
                                mt-4
                                class="half"
                            >
                                <p>
                                    Use a predefined profile to quickly add a set of countries for
                                    Georestriction. Any countries not in this list will be
                                    prevented access.
                                </p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex>
                                <label
                                    class="label"
                                    for="georestriction-countries-profile"
                                >Countries from Profile</label>

                                <v-autocomplete
                                    v-if="allowedCountriesProfileLoaded"
                                    id="georestriction-countries-profile"
                                    outline
                                    v-model="item.attributes.accessData.allowedCountriesProfile"
                                    :items="countriesList"
                                    item-value="alpha2Code"
                                    item-text="enShortName"
                                    no-data-text="loading..."
                                    multiple
                                    solo
                                    flat
                                    dense
                                    chips
                                    @change="updateData()"
                                >
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>

                        <div v-if="item.attributes.accessData.allowedCountriesSpecific.length">
                            <p>
                                These countries should be removed from the data, please notify
                                Urban Zoo with this asset ID
                            </p>
                            <pre>{{
                                item.attributes.accessData.allowedCountriesSpecific
                            }}</pre>
                        </div>

                        <!-- <v-layout row>
              // Removed due to allowing editing on main list
              <v-flex>
                <label class="label" for="georestriction-countries-specific">Manually allowed countries</label>

                <v-autocomplete
                  id="georestriction-countries-specific"
                  outline
                  v-model="item.attributes.accessData.allowedCountriesSpecific"
                  :items="countriesList"
                  item-value="alpha2Code"
                  item-text="enShortName"
                  no-data-text="loading..."
                  multiple
                  solo
                  flat
                  clearable
                  dense
                  chips
                  @change="updateData()"
                  >
                </v-autocomplete>
              </v-flex>
            </v-layout> -->
                    </template>
                </section>

                <h3 class="subheading">Tagging</h3>
                <section class="section-container">
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="third-x2"
                        >
                            <label
                                class="label"
                                for="categories"
                            >Categories</label>

                            <v-combobox
                                id="categories"
                                outline
                                v-model="item.attributes.tagData.categories"
                                :items="categories"
                                no-data-text="loading..."
                                multiple
                                solo
                                flat
                                clearable
                                dense
                                chips
                                deletable-chips
                                @change="updateData()"
                            >
                            </v-combobox>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="third"
                        >
                            <p>
                                Apply categories to your video to be able to filter this video
                                via categories
                            </p>
                        </v-flex>
                    </v-layout>

                    <template>
                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >Seasons</label>

                                <v-text-field
                                    :value="item.attributes.tagData.seasons
                                        ? item.attributes.tagData.seasons.toString()
                                        : ''
                                        "
                                    @change="updateTag('seasons', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>
                                    Comma separated list of <i>seasons</i>. Please enter only the
                                    year when the season started, I.e. for the
                                    <i>2022/2023</i> season you need to enter <i>"2022"</i>
                                </p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >Teams</label>

                                <v-text-field
                                    :value="item.attributes.tagData.teams
                                        ? item.attributes.tagData.teams.toString()
                                        : ''
                                        "
                                    @change="updateTag('teams', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>Comma separated list of <i>team IDs</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >Fixtures</label>

                                <v-text-field
                                    :value="item.attributes.tagData.fixtures
                                        ? item.attributes.tagData.fixtures.toString()
                                        : ''
                                        "
                                    @change="updateTag('fixtures', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>Comma separated list of <i>fixture IDs</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >Players</label>

                                <v-text-field
                                    :value="item.attributes.tagData.players
                                        ? item.attributes.tagData.players.toString()
                                        : ''
                                        "
                                    @change="updateTag('players', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>Comma separated list of <i>player IDs</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >People</label>

                                <v-text-field
                                    :value="item.attributes.tagData.peopleTags
                                        ? item.attributes.tagData.peopleTags.toString()
                                        : ''
                                        "
                                    @change="updateTag('peopleTags', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>Comma separated list of <i>people</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >Tags</label>

                                <v-text-field
                                    :value="item.attributes.tagData.tags
                                        ? item.attributes.tagData.tags.toString()
                                        : ''
                                        "
                                    @change="updateTag('tags', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>Comma separated list of <i>tags</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="third-x2"
                            >
                                <label
                                    class="label"
                                    for="categories"
                                >Search Tags</label>

                                <v-text-field
                                    :value="item.attributes.tagData.searchTags
                                        ? item.attributes.tagData.searchTags.toString()
                                        : ''
                                        "
                                    @change="updateTag('searchTags', $event)"
                                    solo
                                    flat
                                />
                            </v-flex>
                            <v-flex
                                mt-4
                                class="third"
                            >
                                <p>Comma separated list of <i>search tags</i></p>
                            </v-flex>
                        </v-layout>
                    </template>
                </section>

                <h3 class="subheading">Downloads</h3>
                <section class="section-container">
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label published-row__label"
                                for="published"
                            >Master download</label>

                            <template v-if="masterDownloadChecking">
                                <inline-loader />
                            </template>

                            <template v-else>
                                <template v-if="item.attributes.assetData.masterAccess === 'temporary'">
                                    <template v-if="item.attributes.assetData.master">
                                        <v-btn
                                            v-if="item.attributes.assetData.master && item.attributes.assetData.master.status === 'preparing'
                                            "
                                            disabled
                                            class="section-button"
                                            color="accent"
                                            type="button"
                                            outline
                                            flat
                                        >
                                            <v-icon
                                                class="mr-2 mb-1"
                                                small
                                            >downloading</v-icon>
                                            Master Download Preparing
                                        </v-btn>

                                        <div
                                            v-else-if="item.attributes.assetData.master && item.attributes.assetData.master.status === 'ready'">
                                            <v-text-field
                                                prepend-icon="download_for_offline"
                                                solo
                                                flat
                                                readonly
                                                :value="item.attributes.assetData.master.url"
                                            /> <v-btn
                                                @click="enableMasterDownload()"
                                                class="section-button"
                                                color="accent"
                                                type="button"
                                                outline
                                                flat
                                            >
                                                <v-icon
                                                    pr-2
                                                    small
                                                >download</v-icon>
                                                Generate New Master Download
                                            </v-btn>
                                        </div>
                                    </template>

                                    <p v-else>Something has gone wrong</p>
                                </template>

                                <template v-else>
                                    <v-btn
                                        @click="enableMasterDownload()"
                                        class="section-button"
                                        color="accent"
                                        type="button"
                                        outline
                                        flat
                                    >
                                        <v-icon
                                            pr-2
                                            small
                                        >download</v-icon>
                                        Enable Master Download
                                    </v-btn>
                                </template>
                            </template>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                You can download the master copy of any resource from here. The
                                download link will last for 24 hours, after which you need to generate a new one.
                            </p>
                        </v-flex>
                    </v-layout>
                </section>

                <h3 class="subheading">Publishing</h3>

                <section class="section-container">
                    <v-layout
                        row
                        v-if="modules.default.LFCEXclusive"
                    >
                        <v-flex
                            mr-4
                            class="half"
                        >
                            <div class="published-row">
                                <label
                                    class="label published-row__label"
                                    for="published"
                                >Disable Adverts</label>
                                <v-select
                                    id="published"
                                    v-model="item.attributes.disableAdvertising"
                                    item-text="name"
                                    item-value="value"
                                    :items="[
                                        { name: 'Yes, prevent adverts', value: true },
                                        { name: 'No, allow adverts', value: false },
                                    ]"
                                    solo
                                    flat
                                    required
                                    @change="updateData()"
                                />
                            </div>
                        </v-flex>

                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>Adverts will display by default if not manually disabled</p>
                            <p>
                                <i>This field only allows the adverts to be displayed. The
                                    adverts displaying is dictated based on the available adverts
                                    to the user</i>
                            </p>
                        </v-flex>
                    </v-layout>

                    <v-layout
                        row
                        class="published-wrap"
                    >
                        <v-flex
                            mr-4
                            class="half"
                        >
                            <div class="published-row">
                                <label
                                    class="label published-row__label"
                                    for="published"
                                >Published</label>
                                <v-select
                                    id="published"
                                    v-model="item.attributes.publicationData.published"
                                    item-text="name"
                                    item-value="value"
                                    :items="[
                                        { name: 'Draft', value: 0 },
                                        { name: 'Published', value: 1 },
                                    ]"
                                    solo
                                    flat
                                    required
                                    @change="updateData()"
                                />
                            </div>
                        </v-flex>

                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                <i>Required</i> - If you wish to immediately display your asset,
                                simply set its status to 'published.' However, if you want to
                                schedule a video for future release, please keep it as a draft
                                and set the publishing date below.
                            </p>
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="publish"
                            >Published date <sup>*</sup></label>

                            <datetime
                                v-model="item.attributes.publicationData.startAt"
                                type="datetime"
                                @change="updateData()"
                                :class="{
                                    'datetime-error': basicValidateCheck(item.attributes.startAt),
                                }"
                            ></datetime>

                            <p class="utc-note">
                                This will publish at
                                {{ item.attributes.publicationData.startAt }}
                            </p>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                <i>Required - </i> Please enter the start date and time as your
                                local time
                            </p>
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="expiry"
                            >Expiry date</label>

                            <datetime
                                v-model="item.attributes.publicationData.finishAt"
                                type="datetime"
                                @change="updateData()"
                            ></datetime>

                            <p class="utc-note">
                                This will expire at
                                {{ item.attributes.publicationData.finishAt }}
                            </p>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                Please enter the expiry date and time as your local time if you
                                want the post to automatically remove
                            </p>
                        </v-flex>
                    </v-layout>
                </section>

                <v-btn
                    color="error"
                    @click="deleteItem()"
                >Delete</v-btn>

                <StickyFooter
                    :errorMessage="errorMessage"
                    :status="status"
                    @buttonClicked="saveItem()"
                />
            </v-tab-item>

            <!-- <v-tab :ripple="{ class: 'accent--text' }">Social Sharing</v-tab>
      <v-tab-item px-0 class="main-body">
        <VideoSocialClip
          v-if="active === 1"
          :item="item" />
      </v-tab-item> -->

            <v-tab :ripple="{ class: 'accent--text' }">Clip Asset</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <VideoNewClip
                    v-if="active === 1"
                    :item="item"
                />
            </v-tab-item>

            <template v-if="item.attributes.migrationData">
                <v-tab :ripple="{ class: 'accent--text' }">Migrated Data</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <section class="section-container">
                        <v-layout row>
                            <v-flex>
                                <pre class="json-render">{{
                                    item.attributes.migrationData
                                }}</pre>
                            </v-flex>
                        </v-layout>
                    </section>
                </v-tab-item>
            </template>

            <!-- <v-tab :ripple="{ class: 'accent--text' }">Analytics</v-tab>
      <v-tab-item px-0 class="main-body">
        <div class="triple-stats">
          <single-stat class="triple-stats__item" :title="`Views`" :value="1004" />

          <single-stat class="triple-stats__item" :title="`Unique Viewers`" :value="868" />

          <single-stat class="triple-stats__item" :title="`Playing Time`" :value="`1.1 hrs`" />
        </div>
      </v-tab-item> -->
        </v-tabs>

        <v-dialog
            persistent
            v-model="subtitleDialog"
            width="580"
            scrollable
            lazy
        >
            <v-card>
                <v-card-title class="card-title">Add subtitle</v-card-title>

                <v-card-text>
                    <div
                        v-if="subtitleDialog"
                        class="upload-wrapper"
                    >
                        <label
                            class="label"
                            for="subtitle-upload"
                        >Select Subtitle File</label>
                        <input
                            id="file-upload"
                            ref="fileUpload"
                            type="file"
                            @change="convertSubtitle($event)"
                            accept=".srt,.vtt"
                        />
                    </div>

                    <div>
                        <label
                            class="label"
                            for="lang-code"
                        >Language Code</label>
                        <v-text-field
                            id="lang-code"
                            v-model="addSubtitle.languageCode"
                            solo
                            flat
                            required
                        ></v-text-field>
                    </div>

                    <div>
                        <label
                            class="label"
                            for="closed-captions"
                        >Closed Captions</label>
                        <v-select
                            id="closed-captions"
                            v-model="addSubtitle.closedCaptions"
                            item-text="name"
                            item-value="value"
                            :items="[
                                { name: 'Yes', value: true },
                                { name: 'No', value: false },
                            ]"
                            solo
                            flat
                            required
                        />
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outline
                        flat
                        @click="cancelSubtitle()"
                    > Cancel </v-btn>
                    <v-btn
                        color="accent"
                        outline
                        flat
                        :disabled="validateSubtitleEntry()"
                        @click="createSubtitle()"
                    >
                        Add Subtitle
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import axios from "axios";
import EntitlementsPicker from "@/components/Library/EntitlementsPicker";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";
import VideoPlayer from "@/components/Streamline/VideoPlayer";
import VideoNewClip from "@/components/Streamline/VideoNewClip";
import VideoSocialClip from "@/components/Streamline/VideoSocialClip";
import SingleStat from "@/components/Library/StatBlocks/SingleStat";
import SquadTagger from "@/components/Library/Tagging/SquadTagger";
import SeasonTagger from "@/components/Library/Tagging/SeasonTagger";
import FixtureTagger from "@/components/Library/Tagging/FixtureTagger";
import InlineLoader from "@/components/Library/InlineLoader";

export default {
    name: "VideoContent",

    components: {
        EntitlementsPicker,
        ImageUploader,
        StickyFooter,
        VideoPlayer,
        VideoSocialClip,
        VideoNewClip,
        SingleStat,
        SquadTagger,
        SeasonTagger,
        FixtureTagger,
        InlineLoader,
    },

    data: () => ({
        status: "saved",
        errorMessage: "",
        active: null,
        item: {
            attributes: {
                title: null,
                disableAdvertising: false,
                description: "",
                accessData: {
                    entitlements: [],
                    allowedCountriesSpecific: [],
                    countryProfile: "",
                    allowedCountriesProfile: [],
                },
                publicationData: {
                    published: 0,
                },
            },
        },
        enableCountryRestrictions: false,
        countriesList: [],
        countriesLoaded: false,
        georestrictionProfiles: [],
        georestrictionProfilesLoaded: false,
        allowedCountriesProfileLoaded: true,
        categories: [],
        validating: false,
        masterDownloadChecking: false,
        subtitles: [],
        subtitleDialog: false,
        addSubtitle: {
            languageCode: "",
            closedCaptions: false,
            subtitleBase64: "",
        },
        modules: null,
    }),

    mounted() {
        this.modules = require("@/config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
        this.status = "needsSaving";

        this.fetchItem();
        this.getCountries();
        this.fetchGeorestrictionProfiles();
        this.getCategories();
        this.fetchSubtitles();
    },

    methods: {
        updateTag(key, $event) {
            if ($event.length) {
                this.item.attributes.tagData[key] = $event.split(",");
            } else {
                this.item.attributes.tagData[key] = [];
            }

            this.updateData();
        },

        updateCustomPoster(payload) {
            this.item.attributes.imageData.landscape.imageID = payload.mediaLibraryID;
            this.item.attributes.imageData.landscape.imageKey = payload.imageKey;

            this.updateData();
        },

        updateData() {
            this.status = "needsSaving";
            this.errorMessage = "";
            console.log(this.item);
        },

        selectGeorestrictionProfile($event) {
            console.log("selectGeorestrictionProfile", $event);

            this.allowedCountriesProfileLoaded = false;

            this.item.attributes.accessData.allowedCountriesProfile =
                $event.countries;

            this.allowedCountriesProfileLoaded = true;
        },

        fetchGeorestrictionProfiles() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Fetch profiles:", response);
                        const profiles = response.data.data;
                        this.georestrictionProfiles = profiles.map((item) => {
                            return {
                                id: item.id,
                                profileName: item.attributes.profileName,
                                countries: item.attributes.countries,
                            };
                        });

                        this.georestrictionProfilesLoaded = true;
                    })
                    .catch((e) => {
                        console.error("Problem fetching profiles", e);
                        this.georestrictionProfilesLoaded = true;
                    });
            });
        },

        fetchItem() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}?include=social-clip`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Fetch video:", response);
                        this.item = response.data.data;

                        if (!this.item.attributes.imageData.landscape) {
                            this.item.attributes.imageData.landscape = {
                                imageKey: "",
                                imageID: "",
                            };
                        }

                        if (
                            this.item.attributes.accessData.allowedCountriesProfile.length ||
                            this.item.attributes.accessData.allowedCountriesSpecific.length
                        ) {
                            this.enableCountryRestrictions = true;
                        }

                        this.$store.commit("completeLoading");
                        this.status = "needsSaving";
                    })
                    .catch((e) => {
                        console.error("Problem fetching video", e);
                        this.$store.commit("completeLoading");
                        this.status = "needsSaving";
                        this.errorMessage = "Problem fetching video";
                    });
            });
        },

        publishedValidate() {
            if (this.item.attributes.publicationData.published) {
                if (this.item.attributes.publicationData.startAt) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },

        saveItem() {
            if (this.item.attributes.title && this.publishedValidate()) {
                this.$store.commit("startLoading");

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                attributes: {
                                    title: this.item.attributes.title,
                                    description: this.item.attributes.description || "",
                                    disableAdvertising: this.item.attributes.disableAdvertising,
                                    accessData: this.item.attributes.accessData,
                                    publicationData: this.item.attributes.publicationData,
                                    tagData: this.item.attributes.tagData,
                                    imageData: this.item.attributes.imageData,
                                },
                            },
                        },
                    })
                        .then((response) => {
                            console.log("Saved video:", response);
                            this.$store.commit("completeLoading");
                            this.errorMessage = "";
                            this.status = "saved";
                        })
                        .catch((e) => {
                            console.error("Problem saving video", e);
                            this.$store.commit("completeLoading");
                            this.status = "needsSaving";
                            this.errorMessage = "Problem saving video";
                        });
                });
            } else {
                alert("You must have a title and published date set");
                this.errorMessage = "Validation error";
                this.validating = true;
                this.$store.commit("completeLoading");
            }
        },

        getCountries() {
            this.countriesLoaded = false;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_COUNTRIESAPI}/countries`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        this.countriesList = response.data;
                        this.countriesLoaded = true;
                    })
                    .catch((e) => {
                        console.error("Problem receiving countries", e);
                    });
            });
        },

        getCategories() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_VIDEOS_API}/categories`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Fetch categories", response.data.data);
                        const data = response.data.data;
                        this.categories = data.map((category) => {
                            return category.attributes.name;
                        });
                    })
                    .catch((e) => {
                        console.error("Problem getting CATEGORIES", e);
                    });
            });
        },

        deleteItem() {
            if (
                confirm(
                    "This will permanently delete the asset. \nAre you sure you want to continue?"
                )
            ) {
                this.$store.commit("startLoading");

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "DELETE",
                        url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            console.log("Delete item:", response);
                            this.$store.commit("completeLoading");

                            this.$router.push("/streamline");
                        })
                        .catch((e) => {
                            console.error("Problem deleting item", e);

                            this.errorMessage = "Problem deleting video";

                            alert("There was a problem deleting the item");
                            this.$store.commit("completeLoading");
                        });
                });
            }
        },

        basicValidateCheck(value) {
            if (this.validating && !value) {
                return true;
            }
        },

        clearCountryRestrictions() {
            if (!this.enableCountryRestrictions) {
                this.item.attributes.accessData.countryProfile = {};
                this.item.attributes.accessData.allowedCountriesProfile = [];
                this.item.attributes.accessData.allowedCountriesSpecific = [];

                this.updateData();
            }
        },

        updatedEntitlements(payload) {
            console.log("updatedEntitlements", payload);

            const filteredEntitlements = payload.filter(
                (element) => element !== undefined
            );

            this.item.attributes.accessData.entitlements = filteredEntitlements;
            this.updateData();
        },

        checkMasterDownload() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/?fields=assetData.masterAccess,assetData.master`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        const item = response.data.data;
                        console.log("Fetch video:", response);

                        // if (!this.item.attributes.imageData.landscape) {
                        //   this.item.attributes.imageData.landscape = {
                        //     imageKey: '',
                        //     imageID: ''
                        //   }
                        // }

                        if (item.attributes.assetData.master) {
                            if (item.attributes.assetData.master.status == "ready") {
                                this.item.attributes.assetData.master =
                                    item.attributes.assetData.master;
                                this.item.attributes.assetData.masterAccess =
                                    item.attributes.assetData.masterAccess;

                                this.masterDownloadChecking = false;
                            } else {
                                this.masterDownloadChecking = true;

                                setTimeout(() => {
                                    console.log("Checking");
                                    this.checkMasterDownload();
                                }, 5000);
                            }
                        } else {
                            this.masterDownloadChecking = true;

                            setTimeout(() => {
                                console.log("Checking");
                                this.checkMasterDownload();
                            }, 5000);
                        }
                    })
                    .catch((e) => {
                        console.error("Problem fetching video", e);
                    });
            });
        },

        enableMasterDownload() {
            if (
                confirm(
                    "This will temporarily enable a master download link. \nAre you sure you want to continue?"
                )
            ) {
                this.masterDownloadChecking = true;

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/master`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                id: this.$route.params.id,
                                type: "video",
                                attributes: {
                                    assetData: {
                                        masterAccess: "temporary",
                                    },
                                },
                            },
                        },
                    })
                        .then((response) => {
                            console.log("Enable master download:", response);

                            setTimeout(() => {
                                console.log("Checking");
                                this.checkMasterDownload();
                            }, 10000);
                        })
                        .catch((e) => {
                            console.error("Problem enabling master download", e);


                            const downloadAlreadyExistsError = "Download already exists"

                            if (e.response.data && e.response.data.errors && e.response.data.errors[0].detail == downloadAlreadyExistsError) {
                                this.masterDownloadChecking = false;
                                alert(`${downloadAlreadyExistsError}. Please use the existing link`);

                            } else {
                                alert("There was a problem enabling the master download");

                            }
                        });
                });
            }
        },

        convertSubtitle($event) {
            const file = $event.target.files[0];
            const reader = new FileReader();

            reader.addEventListener("load", (evt) => {
                console.log("reader", reader);

                const base64DataOnly = reader.result.split(",")[1].toString();

                this.addSubtitle.subtitleBase64 = base64DataOnly;
            });

            if (file) {
                reader.readAsDataURL(file);
            }
        },

        fetchSubtitles() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/subtitles`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Fetch subtitles:", response);
                        this.subtitles = response.data.data;
                    })
                    .catch((error) => {
                        console.error("Subtitles fetch failed", error);
                    });
            });
        },

        deleteSubtitle(subtitleID) {
            if (confirm("\nAre you sure you want to delete this subtitle?")) {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "DELETE",
                        url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/subtitles/${subtitleID}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            console.log("Delete subtitle:", response);

                            this.addSubtitle = {
                                languageCode: "",
                                closedCaptions: false,
                                subtitleBase64: "",
                            };
                            this.subtitleDialog = false;

                            this.fetchSubtitles();
                        })
                        .catch((error) => {
                            console.error("Subtitles delete failed", error);
                        });
                });
            }
        },

        createSubtitle() {
            if (confirm("\nAre you sure you want to save this subtitle?")) {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "POST",
                        url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${this.$route.params.id}/subtitles`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                attributes: {
                                    assetData: {
                                        type: "text",
                                        textType: "subtitles",
                                        languageCode: this.addSubtitle.languageCode,
                                        closedCaptions: this.addSubtitle.closedCaptions,
                                    },
                                    subtitle: this.addSubtitle.subtitleBase64,
                                    videoID: this.$route.params.id,
                                },
                                type: "subtitle",
                            },
                        },
                    })
                        .then((response) => {
                            console.log("Create subtitles:", response);
                            this.addSubtitle = {
                                languageCode: "",
                                closedCaptions: false,
                                subtitleBase64: "",
                            };
                            this.subtitleDialog = false;

                            this.fetchSubtitles();
                        })
                        .catch((error) => {
                            console.error("Subtitles create failed", error);
                        });
                });
            }
        },

        cancelSubtitle() {
            this.addSubtitle = {
                languageCode: "",
                closedCaptions: false,
                subtitleBase64: "",
            };
            this.subtitleDialog = false;
        },

        openSubtitlesEntry() {
            this.subtitleDialog = true;
            setTimeout(() => {
                console.log("Subtitles:", this.$refs.fileUpload.value);
            }, 100);
        },

        validateSubtitleEntry() {
            if (
                !this.addSubtitle.languageCode.length ||
                !this.addSubtitle.subtitleBase64.length
            ) {
                return true;
            }
        },

        // addSquadTag($event) {
        //   console.log('addSquadTag', $event)
        //   this.item.attributes.tagData.squads = $event
        //   this.status = 'needsSaving';
        // },

        // addSeasonTag($event) {
        //   console.log('addSeasonTag', $event)
        //   this.item.attributes.tagData.seasons = $event
        //   this.status = 'needsSaving';
        // }
    },
};
</script>

<style scoped lang="scss">
.half {
    width: 50%;
}

.third-x2 {
    width: 66.66%;
}

.third {
    width: 33.33%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}

.image {
    width: 100%;
    max-width: 300px;
}

.triple-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__item {
        width: calc(33.33% - 16px);
    }
}

.info-table {
    width: 100%;
    border: 1px solid #dfe3e9;
    border-radius: 4px;

    td {
        padding: 4px 8px;
        border-bottom: 1px solid #dfe3e9;

        &:first-child {
            padding-right: 20px;
            font-weight: 500;
            border-right: 1px solid #dfe3e9;
        }
    }

    tr:last-child {
        td {
            border-bottom: 0;
        }
    }
}

.preview-video {
    margin: 16px 0 0 0;
    width: 50%;
}

.error-message {
    margin: 0 0 12px;
    padding: 6px;
    color: #ff5252;
    border: 1px solid #ff5252;
    word-break: break-all;
}

.json-render {
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.subtitles-table {
    width: 100%;

    th {
        text-align: left;
    }
}

.upload-wrapper {
    margin-bottom: 32px;
}

.refresh {
    text-align: right !important;
}
</style>
